import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NmiService {
  private apiUrl = environment.api.nest.uri;

  constructor(private http: HttpClient) { }

  /**
   * Refunds a transaction in NMI
   * @param transactionId The transaction ID to refund
   * @param amount The amount to refund
   * @param idConcurso The competition ID
   * @param securityKey The security key for the API
   * @returns Observable with the refund response
   */
  refundTransaction(
    transactionId: string, 
    amount: number, 
    idConcurso: string, 
  ): Observable<any> {
    const url = `${this.apiUrl}betterpay/refundTransaction/${transactionId}`;
    const body = {
      type: 'refund',
      amount: amount.toString(),
      id_concurso: idConcurso
    };
    
    console.log('Body in Service', body);

    return this.http.post(url, body);
  }
} 